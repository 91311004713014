<template>
  <div class="merchant-info">
    <div v-if="info" class="list">
      <div class="part-name">商户信息</div>
      <van-cell title="商户图标">
        <van-image v-if="info.merLogoUrl" class="icon" round fit="fill" width="30px" height="30px"
                   :src="info.merLogoUrl"/>
      </van-cell>
      <van-cell title="商户名称" :value="info.merName"/>
      <van-cell title="商户类型" :value="getMerType(info.merType)"/>
      <van-cell title="商户地址" :value="info.merAddress"/>
      <div class="part-name">经营者信息</div>

      <van-cell title="企业名称" :value="info.companyName"/>
      <van-cell title="企业证件类型" :value="getCompanyCertType(info.companyCertType)"/>
      <van-cell title="企业证件号码" :value="info.companyCertNo"/>
      <van-cell title="证件类型" :value="getCertificateType(info.certificateType)"/>
      <van-cell title="证件号码" :value="info.certificateNo"/>
      <van-cell title="联系人" :value="info.merContactName"/>
      <van-cell title="联系电话" :value="info.merContactPhone"/>
      <div class="part-name">结算信息</div>
      <van-cell title="抽成比例" @click="setRate">
        <span v-text="getRateInput"></span>
        <van-icon class="set-rate" size="20" name="edit"/>
      </van-cell>
      <van-cell title="开户银行编号" :value="info.acctBankCode"/>
      <van-cell title="开户银行名称" :value="info.acctBankName"/>
      <van-cell title="账号类型" :value="getAcctCardType(info.acctCardType)"/>
      <van-cell title="开户名" :value="info.acctName"/>
      <van-cell title="收款银行账号" :value="info.acctNo"/>
      <van-cell title="银行预留电话" :value="info.acctPhone"/>
      <van-cell title="银行预留证件号码" :value="info.certificateNo"/>
      <div class="part-name">附件</div>
      <van-image style="display: block; min-height: 120px; margin: 10px 16px; background: #F5F7FA"
                 :src="info.companyCertPhotoUrl"/>
      <div class="tips">商户营业执照</div>
      <van-image style="display: block; min-height: 120px; margin: 10px 16px; background: #F5F7FA"
                 :src="info.certificateFrontPhotoUrl"/>
      <div class="tips">联系人身份证正面</div>
      <van-image style="display: block; min-height: 120px; margin: 10px 16px; background: #F5F7FA"
                 :src="info.certificateBackPhotoUrl"/>
      <div class="tips">联系人身份证背面</div>
      <van-image style="display: block; min-height: 120px; margin: 10px 16px; background: #F5F7FA"
                 :src="info.auxiliaryMaterialsPhotoUrl"/>
      <div class="tips">辅助材料</div>

      <van-popup v-model="showPopup" closeable close-icon="close" position="right"
                 :style="{ width: '100%',height: '100%' }" @close="closePopup">
        <van-field label="抽成比例" class="rate-input" type="number" :min="0" v-model="rateInput" placeholder="请输入抽成比例"
                   :formatter="formatterRateInput">
          <template #right-icon>%</template>
        </van-field>
        <div class="rate-input-tips">Tips: 输入1则代表费率为1%</div>
        <van-field label="清分账期" class="into-account-duration-input" type="digit" v-model="intoAccountDurationInput"
                   placeholder="请输入清分账期">
          <template #right-icon>天</template>
        </van-field>
        <van-field label="自动到账" disabled="disabled" class="into-account-duration-input">
          <template #button>
            <van-switch size="20px" active-color="#F1BD20" v-model="autoReceipt" />
          </template>
        </van-field>
        <van-button class="submit-button" block type="primary" color="#F1BD20" size="normal" @click="completeRateInput">
          保存
        </van-button>
      </van-popup>
    </div>
    <template v-else>
      <van-empty v-if="!isLoading" description="暂无商户信息"/>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import {Toast, NumberKeyboard, Notify} from 'vant';

Vue.use(NumberKeyboard);
export default {
  name: "merchant_info",
  data() {
    return {
      isLoading: true,
      showPopup: false,
      rateInput: null,
      intoAccountDurationInput: '',
      autoReceipt: false,
      info: null
    }
  },
  computed: {
    getCompanyCertType() {
      return (value) => {
        // U: 营业执照，Y: 组织机构代码证，V: 统一社会信用代码
        let type = value ? value.toString() : ''
        return type === 'U' ? '营业执照' : type === 'Y' ? '组织机构代码证' : type === 'V' ? '统一社会信用代码' : '其他证件'
      }
    },
    getCertificateType() {
      return (value) => {
        // 证件类型，I:身份证，P:护照，G:军官证，U:其他证件
        let type = value ? value.toString() : ''
        return type === 'I' ? '身份证' : type === 'P' ? '护照' : type === 'G' ? '军官证' : '其他证件'
      }
    },
    getMerType() {
      return (value) => {
        let type = value ? Number.parseInt(value.toString()) : ''
        return type === 1 ? '个人' : type === 2 ? '企业' : '个体工商户'
      }
    },
    getAcctCardType() {
      return (value) => {
        let type = value ? Number.parseInt(value.toString()) : ''
        // 401：借记卡，601：企业户，701：二类户，702：三类户	string
        return type === 401 ? '借记卡' : type === 601 ? '企业户' : type === 701 ? '二类户' : type === 702 ? '三类户' : '其他类型'
      }
    },
    getRateInput() {
      return this.rateInput ? this.rateInput + '%' : ''
    }
  },
  mounted() {
    const id = this.$route.query.id
    if (!id) {
      this.$router.back()
      return
    }
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
    });
    Http.post('frontend/merchant/querySingleMerchant', {id}).then(result => {
      if (result && result.code === 200 && result.data) {
        this.info = result.data
        this.rateInput = result.data.parentRates ? result.data.parentRates[0].rate : ''
        this.intoAccountDurationInput = result.data.intoAccountDuration
        this.autoReceipt = result.data.autoReceipt
      }
    }).catch(error => {
      // console.log(error)
    }).finally(() => {
      this.isLoading = false
      Toast.clear()
    })
  },
  methods: {
    formatterRateInput(value) {
      return value < 0 ? 0 : value
    },
    setRate() {
      this.showPopup = true
    },
    closePopup() {
      this.rateInput = this.info.parentRates ? this.info.parentRates[0].rate : ''
      this.intoAccountDurationInput = this.info.intoAccountDuration
      this.showPopup = false
    },
    completeRateInput() {
      Toast.loading({
        message: '保存中...',
        forbidClick: true,
      });
      Http.post('frontend/merchant/modifyMerchantRate', {
        merchantId: this.info.id,
        parentRate: this.rateInput,
        intoAccountDuration: this.intoAccountDurationInput,
        autoReceipt: this.autoReceipt
      }).then(result => {
        // console.log(result)
        if (result && result.code === 200) {
          Notify({type: 'success', message: '抽成比例设置成功'});
          this.info.parentRates[0].rate = Number.parseFloat(this.rateInput)
          this.info.intoAccountDuration = this.intoAccountDurationInput
          this.showPopup = false
        } else {
          Notify({type: 'danger', message: result.data || '抽成比例设置失败'});
        }
      }).catch(error => {
        // console.log(error)
      }).finally(() => {
        Toast.clear()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.merchant-info {
  ::v-deep .van-cell__title {
    flex: 0 1 auto;
  }

  ::v-deep .el-image__error {
    min-height: 120px;
  }

  ::v-deep .van-field__control:disabled {
    color: #323233;
    -webkit-text-fill-color: #323233;
  }

  ::v-deep .van-field--disabled .van-field__label {
    color: #323233;
  }

  .part-name {
    background: #eee;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
  }

  .tips {
    text-align: center;
    font-size: 12px;
    margin-bottom: 20px;
    color: #C0C4CC;
  }

  .set-rate {
    vertical-align: middle;
    padding-left: 10px;
  }

  .rate-input {
    width: 90%;
    margin: 80px auto 0 auto;
    background: #f1f1f1;
    border-radius: 4px;
  }

  .into-account-duration-input {
    width: 90%;
    margin: 30px auto 0 auto;
    background: #f1f1f1;
    border-radius: 4px;
  }

  .into-account-duration-input ::v-deep .van-field__button {
    height: 24px;
  }

  .rate-input-tips {
    font-size: 12px;
    width: 90%;
    line-height: 2;
    letter-spacing: 2px;
    text-indent: 6px;
    margin: 0px auto;
    color: #E60000;
    font-weight: 500;
  }

  .submit-button {
    width: 90%;
    margin: 30px auto 0 auto;
  }


}


</style>